<template>
  <div>
    <b-card no-body v-if="report.TABS.length==1">
      <div class="row m-2">
          <div class="col-12">
              <div class="box-form">
                  <div class="row">
                      <div :class="listOfParamNameValues.length<4?'col-4':'col-3'" v-for="i in listOfParamNameValues" :key="i.name">
                          <div class="info-row">
                              <div class="key">{{i.label}}</div>
                              <div class="value">
                                  <div class="select-custom">
                                      <Select2 v-if="i.UIType=='Menu'" v-model="i.selected" :options="i.options"/>
                                      <flat-pickr v-if="i.UIType=='Date'" v-model="i.selected" class="form-control" :config="dateConfig" :placeholder="i.label"/>
                                      <input v-if="i.UIType=='Text'" v-model="i.selected" type="text" class="form-control">
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-12 pt-3 align-content-center text-center">
                          <a class="btn btn-primary" @click="viewReport()">
                              <span class="icon  one-file-chart"></span> Xem báo cáo
                          </a>
                          <a class="btn btn-primary ml-2" href="#">
                              <span class="icon  one-print"></span> In báo cáo
                          </a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </b-card>
    <b-card no-body v-if="report.TABS.length>1">
      <b-tabs v-model="tabIndex">
        <b-tab v-for="tab in report.TABS" :title="tab.title" :key="tab.bi_report_path">
          <div class="row m-2">
            <div class="col-12">
                <div class="box-form">
                    <div class="row">
                        <div :class="listOfParamNameValues.length<4?'col-4':'col-3'" v-for="i in listOfParamNameValues" :key="i.name">
                            <div class="info-row">
                                <div class="key">{{i.label}}</div>
                                <div class="value">
                                    <div class="select-custom">
                                        <Select2 v-if="i.UIType=='Menu'" v-model="i.selected" :options="i.options"/>
                                        <flat-pickr v-if="i.UIType=='Date'" v-model="i.selected" class="form-control" :config="dateConfig" :placeholder="i.label"/>
                                        <input v-if="i.UIType=='Text'" v-model="i.selected" type="text" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 pt-3 align-content-center text-center">
                            <a class="btn btn-primary" @click="viewReport()">
                                <span class="icon  one-file-chart"></span> Xem báo cáo
                            </a>
                            <a class="btn btn-primary ml-2" href="#">
                                <span class="icon  one-print"></span> In báo cáo
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card no-body v-if="report.TABS.length==0 && code==''">
      <h5 class="p-1"><feather-icon icon="AlertTriangleIcon"/> Vui lòng chọn báo cáo để xem!</h5>
    </b-card>
    <b-card no-body v-if="report.TABS.length==0 && code!=''">
      <h5 class="p-1"><feather-icon icon="AlertTriangleIcon"/> Vui lòng kiểm tra lại cấu hình BI Report, mỗi báo cáo phải có ít nhất 1 BI Report!</h5>
    </b-card>
    <b-card no-body v-if="report.TABS.length>0">
      <vue-pdf-app class="rounded" style="height: 800px;" :pdf="pdf" @pages-rendered="pagesRendered"></vue-pdf-app>
    </b-card>
  </div>
</template>

<script>
import { BRow, BCol,BCard, BTab, BCardText,BTabs } from "bootstrap-vue";
import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'
import useJwt from "@/auth/jwt/useJwt";
import Select2 from 'v-select2-component'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

export default {
  components: {BRow,BCol,VuePdfApp,Select2,BCard,flatPickr,BTab, BCardText, BTabs, FeatherIcon},
  data() {
    return {
      pdf: null,
      dateConfig: {
        altInput: true,
        dateFormat: 'd/m/Y',
        altFormat: 'd/m/Y'
      },
      report: {TABS:[]},
      tab: null,
      tabIndex: 0,
      listOfParamNameValues:[]
    };
  },
  watch:{
    tabIndex(){
      this.pdf = null
      this.loading(true)
      this.tab = this.report.TABS[this.tabIndex]
      this.loadParam()
    },
    '$route.query.code'() {
      this.loading(true)
      this.report = JSON.parse(decodeURIComponent(atob(this.$route.query.code)))
      if (this.report.TABS != null && this.report.TABS!=""){
        this.report.TABS = JSON.parse(this.report.TABS)
        this.tab = this.report.TABS[0]
      }else{
        this.report.TABS=[]
      }
      document.querySelectorAll("[name='reportLink']").forEach(e=>
        e.classList.remove("active")
      )
      document.querySelector("[code='"+this.report.CODE+"']").classList.add("active")
      this.loadParam();
      console.log(this.report)
    }
  },
  mounted () {
    
  },
  created() {
    
  },
  methods: {
    loadParam(){
      try{
        useJwt.get('parameters?report='+this.tab.bi_report_path.substring(1),{}).then((response) => {
          this.listOfParamNameValues = response.data.listOfParamNameValues.item
          this.listOfParamNameValues.forEach(function (v) {
            if (v.UIType === 'Menu') {
              var options = []
              v.values.item.forEach(function (x, i) {
                options.push({'text': v.lovLabels.item[i], 'id': x})
              })
              v.options = options
            }
          })
          this.loading(false)
        })
      }catch(e){
        this.loading(false)
      }
    },
    pagesRendered(pdfApp) {
      setTimeout(() => (pdfApp.pdfViewer.currentScaleValue = "page-width"));
    },
    base64ToArrayBuffer (base64) {
      // eslint-disable-next-line camelcase
      var binary_string = window.atob(base64)
      var len = binary_string.length
      var bytes = new Uint8Array(len)
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i)
      }
      return bytes.buffer
    },
    viewReport (item) {
      this.loading(true)
      useJwt.post('run',
        {
          report: this.tab.bi_report_path.substring(1),
          type: 'pdf',
          file_name: this.tab.bi_report_path.split("/").slice(-1)[0] + '.pdf',
          items: this.listOfParamNameValues
        },
        {headers: {'apiKey': 'x'}}).then(
          (response) => {
            this.pdf = this.base64ToArrayBuffer(response.data)
            this.loading(false)
          },
          (error) => { this.loading(false) }
        )
    }
  }
};
</script>
<style>
.pdf-app .toolbar{
  z-index: 100;
}
.select2-selection--single:before {
  content: "";
  position: absolute;
  right: 14px;
  top: 0;
  bottom: 0;
  width: 14px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDkgMSIgc3Ryb2tlPSIjMDBCMEVDIiBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==") center / contain no-repeat;
}
.select2-selection__arrow{
  background: none;
  display: none !important;
}
.vgt-table td,.vgt-table th{
  padding: 0.25em 0.35em !important;
  vertical-align: middle !important;
  font-size: 1rem;
}
.vgt-table th{
  text-align: center;
}
</style>
<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
